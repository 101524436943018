<template>
  <div class="p-4 rounded-md mt-4 bg-gray-50 border">
    <p class="font-medium text-gray-800">Host info</p>
    <p class="text-sm text-gray-500">This information will be shown on your listing(s).</p>
    <div class="mt-4 grid grid-cols-6 gap-4">
      <div class="sm:col-span-2">
        <div class="rounded-md border bg-white">
          <div class="px-3 py-0.5">
            <label for="user-photo" class="block text-sm font-medium text-gray-700"> Photo </label>
          </div>
          <div class="mt-1 flex flex-col items-center">
            <img
              v-if="media?.media"
              class="inline-block h-24 w-24 rounded-full"
              :src="media?.media"
              alt="" />
            <img
              v-else
              class="inline-block h-24 w-24 rounded-full"
              src="https://owayy.imgix.net/assets/dashboard/default-user.png?w=350&h=350&fit=max&auto=format,compress"
              alt="" />
            <div class="w-full border-t mt-3 py-1 flex justify-center">
              <div class="btn btn-xs btn-white relative">
                <label for="user-photo" class="relative text-sm font-medium text-blue-gray-900">
                  <span>Change</span>
                  <span class="sr-only"> user photo</span>
                </label>
                <input id="user-photo" name="user-photo" type="file" class="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md" @change="handleFileSelect" />
              </div>
              <button
                type="button"
                class="ml-3 btn btn-xs btn-transparent "
                @click="removePhoto">
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="sm:col-span-4">
        <div>
          <label for="host-name" class="block text-sm font-medium text-gray-700"> Name </label>
          <input id="host-name" :value="name" type="text" name="host-name" autocomplete="name" class="mt-1 block w-full border rounded-md text-blue-gray-900 sm:text-sm"
                 @input="$emit('update:name', $event.target.value)" />
        </div>
        <div class="mt-3">
          <label for="description" class="block text-sm font-medium text-gray-700"> Description </label>
          <div class="mt-1">
            <textarea id="description" :value="description" name="description" rows="4" class="block w-full border rounded-md sm:text-sm"
                      @input="$emit('update:description', $event.target.value)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useMutation } from "@vue/apollo-composable";
import { DELETE_MEDIA } from "@/graphql/products/mutations";

const allowedImages = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/svg+xml"
];

export default {
  name: "UserInfoUI",
  props: {
    media: {
      type: Object
    },
    name: {
      type: String
    },
    description: {
      type: String
    }
  },
  emits: ['update:name', 'update:description', 'update:media'],
  setup(props, { emit }) {
    const uploadError = ref(null);

    const toBase64 = (file) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    })

    const handleFiles = async (files, type = "image") => {
      try {
        const arrFiles = Object.values(files);
        uploadError.value = "";

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < arrFiles.length; i++) {
          if (type === "image" && !allowedImages.includes(files[i].type)) {
            uploadError.value = "Images are only allowed";
            return;
          }
        }

        // eslint-disable-next-line no-return-await
        const mapFiles = async (files) => await Promise.all(
          // eslint-disable-next-line no-return-await
          files.map( async (file) => {
            if (file.size / 1000000 < 100) {
              return {
                media: {
                  path: window.URL.createObjectURL(file)
                },
                file: await toBase64(file)
              }
            }
            uploadError.value = `File is to large it must be equal or less than ${this.sizeLimit} mb`;
            return file;
          }).filter(file => file !== undefined));

        const upload = await mapFiles(arrFiles);
        emit('update:media', {
          media: upload[0].media.path,
          media_id: null,
          file: upload[0].file
        })
      } catch (err) {
      }
    }

    const handleFileSelect = async (e) => {
      const files = e.target.files;
      await handleFiles(files);
    }

    const { mutate: deleteMedia } = useMutation(DELETE_MEDIA);

    const removePhoto = () => {
      if (props.media.media_id) {
        deleteMedia({
          id: +props.media.media_id,
          userId: null
        });
      }
      emit('update:media', {
        media: null,
        media_id: null
      })
    }

    return {
      handleFileSelect,
      removePhoto
    }
  }
};
</script>

<style scoped>

</style>
