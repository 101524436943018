<template>
  <div>
    <SectionWrapper
      title="Profile"
      description="Make sure to keep your profile updated."
      class="focus-within:border-highlight-500 focus-within:ring-1 focus-within:ring-highlight-500"
      :is-valid-section="isValidSection"
      :loading="loadingSection"
      @saveSection="submit">
      <div class="grid grid-cols-6 gap-4">
        <div class="col-span-6 md:col-span-6">
          <div>
            <div class="max-w-full w-64 pb-2">
              <label for="name" class="block text-sm font-medium text-gray-700">First Name</label>
              <div class="mt-1">
                <input
                  id="name"
                  v-model="firstName"
                  type="text"
                  name="name"
                  autocomplete="off"
                  class="focus:ring-highlight-500 focus:border-highlight-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Enter your first name" />
              </div>
              <span class="input-error">{{ errors.firstName }}</span>
            </div>
            <div class="max-w-full w-64 pb-2">
              <label for="surname" class="block text-sm font-medium text-gray-700">Last Name</label>
              <div class="mt-1">
                <input
                  id="surname"
                  v-model="lastName"
                  type="text"
                  name="surname"
                  autocomplete="off"
                  class="focus:ring-highlight-500 focus:border-highlight-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Enter your last name" />
              </div>
              <span class="input-error">{{ errors.lastName }}</span>
            </div>
            <div class="max-w-full w-64">
              <label for="email" class="block text-sm font-medium text-gray-700">E-mail</label>
              <div class="mt-1">
                <input
                  id="email"
                  v-model="email"
                  type="email"
                  name="email"
                  autocomplete="off"
                  class="focus:ring-highlight-500 focus:border-highlight-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="email@example.com" />
              </div>
              <span class="input-error">{{ errors.email }}</span>
            </div>
            <!--            <p id="email-description" class="mt-2 text-sm text-gray-500">-->
            <!--              If you change this-->
            <!--            </p>-->
          </div>
        </div>
        <div class="col-span-6 md:col-span-6 border-t py-4">
          <form @submit.prevent="submitPass">
            <div class="max-w-full w-64">
              <label for="oldPassword" class="block text-sm font-medium text-gray-700">Old password</label>
              <div class="mt-1">
                <input
                  id="oldPassword"
                  v-model="oldPassword"
                  type="password"
                  name="password"
                  autocomplete="new-password"
                  class="
                    focus:ring-highlight-500 focus:border-highlight-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  " />
              </div>
              <span class="input-error">{{ errorPass.oldPassword }}</span>
            </div>
            <div class="max-w-full w-64 mt-2">
              <label for="newPassword" class="block text-sm font-medium text-gray-700">New password</label>
              <div class="mt-1">
                <input
                  id="newPassword"
                  v-model="newPassword"
                  type="password"
                  name="password"
                  autocomplete="new-password"
                  class="
                    focus:ring-highlight-500 focus:border-highlight-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  " />
              </div>
              <span class="input-error">{{ errorPass.newPassword }}</span>
            </div>
            <div class="max-w-full w-64 mt-2">
              <label for="newPassword" class="block text-sm font-medium text-gray-700">Confirm new password</label>
              <div class="mt-1">
                <input
                  id="confirmNewPassword"
                  v-model="confirmNewPassword"
                  type="password"
                  name="password"
                  autocomplete="new-password"
                  class="
                    focus:ring-highlight-500 focus:border-highlight-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  " />
              </div>
              <span class="input-error">{{ errorPass.confirmNewPassword }}</span>
            </div>
            <div class="section-footer pt-4">
              <div class="flex justify-start items-center">
                <button class="btn-save-section" type="submit">
                  <LoadingSpinner v-if="loadingPass" class="w-5 h-5" outer-class="text-white" />
                  <span v-else>Change</span>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-span-6 md:col-span-6 border-t py-4">
          <UserInfoUI v-model:name="nickname" v-model:description="userDescription" v-model:media="hostMedia" />
        </div>
      </div>
    </SectionWrapper>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import * as yup from "yup";
import { useField, useForm, useIsFormDirty, useIsFormValid } from "vee-validate";
import { useMutation } from "@vue/apollo-composable";
import { notify } from "@kyvg/vue3-notification";
import UserInfoUI from "@/components/UI/User/UserInfoUI";
import SectionWrapper from "@/components/UI/SectionWrappers/AppSectionWrapper";
import LoadingSpinner from "@/components/UI/Indication/LoadingSpinner";
import { UPDATE_PASSWORD } from "@/graphql/user";

export default {
  name: "General",
  components: { SectionWrapper, UserInfoUI, LoadingSpinner },
  setup() {
    const store = useStore();

    const loadingSection = ref(false);

    const user = computed(() => store.state.auth.user);

    const schema = yup.object().shape({
      email: yup.string().required().email().nullable(),
      nickname: yup.string().nullable(),
      firstName: yup.string().nullable(),
      lastName: yup.string().nullable(),
      userDescription: yup.string().nullable(),
      hostMedia: yup.object()
    });

    const { errors, handleSubmit } = useForm({
      initialValues: {
        email: user.value.email,
        nickname: user.value.nickname,
        firstName: user.value.firstName,
        lastName: user.value.lastName,
        userDescription: user.value.description,
        hostMedia: {
          media: user.value.media ? `${process.env.VUE_APP_ASSETS_URL}/${user.value.media.media.path}` : null,
          media_id: user.value.media ? user.value.media.media.id : null
        }
      },
      validationSchema: schema,
      validateOnMount: true
    });

    const { value: email } = useField("email");
    const { value: nickname } = useField("nickname");
    const { value: firstName } = useField("firstName");
    const { value: lastName } = useField("lastName");
    const { value: userDescription } = useField("userDescription");
    const { value: hostMedia } = useField("hostMedia");

    const isSectionDirty = useIsFormDirty();
    const isSectionValid = useIsFormValid();

    const isValidSection = computed(() => isSectionDirty.value && isSectionValid.value);

    const submit = handleSubmit(async ({ email, firstName, lastName, nickname, userDescription, hostMedia }, { resetForm }) => {
      loadingSection.value = true;
      const preparedData = {
        description: userDescription,
        email,
        first_name: firstName,
        last_name: lastName,
        nickname,
        media: hostMedia.file
          ? {
            file: hostMedia.file,
            position: 1,
            is_primary: true
          }
          : undefined,
        user_roles: [user.value.role_id]
      };

      await store
        .dispatch("auth/updateUser", { id: user.value.id, data: preparedData })
        .then(() => {
          resetForm({
            touched: false,
            values: {
              email: user.value.email,
              firstName: user.value.firstName,
              lastName: user.value.lastName,
              nickname: user.value.nickname,
              userDescription: user.value.description
            }
          });
        })
        .finally(() => {
          loadingSection.value = false;
        });
    });

    const { mutate: updatePassword, loading: loadingPass, onDone, onError } = useMutation(UPDATE_PASSWORD);

    const errorPass = ref({
      oldPassword: "",
      newPassword: ""
    });

    const passSchema = yup.object().shape({
      oldPassword: yup.string().min(6, "Password is short (min 6 characters)").required().nullable(),
      newPassword: yup
        .string()
        .min(6)
        .required()
        .nullable()
        .oneOf([yup.ref("confirmNewPassword"), null], "Passwords must match"),
      confirmNewPassword: yup.string().min(6).required().nullable()
    });

    const { handleSubmit: handleSubmitPass } = useForm({
      initialValues: {
        oldPassword: null,
        newPassword: null,
        confirmNewPassword: null
      },
      validationSchema: passSchema,
      validateOnMount: true
    });

    const { value: oldPassword } = useField("oldPassword");
    const { value: newPassword } = useField("newPassword");
    const { value: confirmNewPassword } = useField("confirmNewPassword");

    const isPassDirty = useIsFormDirty();
    const isPassValid = useIsFormValid();

    const isValidPass = computed(() => isPassDirty.value && isPassValid.value);

    const invalidPass = (ctx) => {
      errorPass.value = ctx.errors;
    };

    const submitPass = handleSubmitPass(({ oldPassword, newPassword }, { resetForm }) => {
      updatePassword({
        data: {
          old_password: oldPassword,
          password: newPassword,
          user_id: null
        }
      });

      onDone(() => {
        notify({
          title: "Password successfully changed",
          type: "success"
        });
        resetForm({
          touched: false,
          values: {
            old_password: null,
            password: null,
            user_id: null
          }
        });
      });

      onError((value) => {
        notify({
          title: value.message,
          type: "error"
        });
      });
    }, invalidPass);

    return {
      email,
      errors,
      userDescription,
      nickname,
      hostMedia,
      isValidSection,
      isSectionDirty,
      submit,
      submitPass,
      isValidPass,
      oldPassword,
      newPassword,
      errorPass,
      loadingSection,
      firstName,
      lastName,
      confirmNewPassword,
      loadingPass
    };
  }
};
</script>

<style scoped></style>
